import { EventTracking } from './eventTracking'

/**
 * Initializes ima adsDisplayContainer if it was not initialized
 * @param {*} imaConfig redux store
 * @param {*} dispatch redux dispatch function
 * @param {*} initializeIma redux store action
 */
export const handleIMAInitialization = (
    imaConfig,
    dispatch,
    initializeIma,
    contentData,
    catalogData,
    playlist
) => {
    if (!imaConfig.initialized) {
        try {
            imaConfig.adsDisplayContainer?.initialize()

            EventTracking('first_listen', {
                contentId: contentData?.id,
                contentTitle: contentData?.title,
                contentDuration: contentData?.duration,
                vertical: contentData?.catalog?.type,
                networkType:
                    (navigator && navigator?.connection?.effectiveType) || '4g',
                source: 'content page',
                catalogId: catalogData?.id,
                catalogTitle: catalogData?.title,
                catalogSource: catalogData?.source,
                elapsedTime: contentData?.meta?.timeElapsed,
                streamType: 'streaming',
            })
            dispatch(
                initializeIma({
                    initialized: true,
                })
            )
        } catch (e) {
            console.log('error', e)
        }
    }
}

import {
    AddToQueueImage,
    CatalogName,
    CheckmarkImage,
    CommentsContainer,
    CommentsIcon,
    CommentsText,
    Container,
    ControlsContainer,
    DownloadImage,
    Duration,
    EpisodeImage,
    EpisodeInfoContainer,
    ListensAndCommentsContainer,
    MetaInfoContainer,
    PlayButton,
    PlayButtonAndDurationContainer,
    PublishedAtText,
    RightSideControlsContainer,
    TitleAndCatalogContainer,
    TitleText,
    EpisodeDescription,
    EpisodeImageContainer,
    PremiumBlocker,
    BadgeContainer,
    Purchased,
    IconButton,
    EpisodeInfoDescriptionContainer,
} from './styles'
import { IMAinitAdPlayingToast } from '@/helpers/IMAinitAdPlayingToast'
import compressedImage from '@/helpers/compressedImage'
import { createDateString } from '@/helpers/createDateString'
import NumberConversion from '@/helpers/numberConversion'
import { radioListenerCountHandler } from '@/helpers/radioListenerHandler'
import SecondsToTime from '@/helpers/secondsToTime'
import { initializeAds } from '@/store/actions/ads'
import { initializeIma } from '@/store/actions/ima'
import {
    setCurrentContentId,
    setCurrentPlayingCatalog,
    setCurrentTrackId,
    setPlaying,
    setPlaylist,
    setShowDownloadPrompt,
    setShowPlayer,
    setShowSubscriptionPrompt,
    setTrackCount,
} from '@/store/actions/player'
import CatalogContext from '@/store/contexts/catalog'
import * as adsSelector from '@/store/selectors/ads'
import * as catalogSelectors from '@/store/selectors/catalog'
import * as imaSelector from '@/store/selectors/ima'
import * as playerSelectors from '@/store/selectors/player'
import * as pubnubSelector from '@/store/selectors/pubnub'
import { leave } from '@/utils/agora'
import { initializeOthersPlaylistAPI } from '@/utils/api-ssr'
import { getCatalogDetails } from '@/utils/getCatalogDetails'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useContext, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

export const EpisodeInformationPage = ({
    content,
    catalogName,
    contentType,
    playlistId,
}) => {
    const dispatch = useDispatch()
    const { data: session } = useSession()
    const pubnub = useSelector(pubnubSelector._pubnub)

    // Ads
    const ads = useSelector(adsSelector._ads)
    const imaConfig = useSelector(imaSelector._ima)

    const playing = useSelector(playerSelectors._playing)
    // const catalogData = useSelector(catalogSelectors._catalogData)
    const { catalogData } = useContext(CatalogContext) || {}

    const currentTrackId = useSelector(playerSelectors._currentTrackId)
    const sort = useSelector(catalogSelectors._sorting)
    const season = useSelector(catalogSelectors._season)
    const joinedLiveroom = useSelector(playerSelectors._joinedLiveroom)

    const router = useRouter()

    const currentPlayingCatalog = useSelector(
        playerSelectors._currentPlayingCatalog
    )
    const isNoicebook = useMemo(
        () => ['audiobook', 'audioseries'].includes(catalogData?.type),
        [catalogData]
    )
    const playEpisodes = useCallback(
        async (id) => {
            if (playing && id === currentTrackId) {
                dispatch(setPlaying(!playing))
            } else {
                radioListenerCountHandler(-1, currentPlayingCatalog?.id)
                const query = {
                    sort: sort === 'Terbaru' ? 'DESC' : 'ASC',
                    isPremium: true,
                }
                if (season) {
                    query.season_number = season
                }

                const response = await getCatalogDetails(
                    content?.catalogId,
                    query,
                    session?.token
                )

                dispatch(setCurrentPlayingCatalog(response?.data))

                const findCurrentTrackIndex =
                    response?.data?.contents?.findIndex(
                        (value) => id === value.id
                    )
                dispatch(setPlaylist(response?.data?.contents))
                dispatch(
                    setCurrentTrackId(
                        response?.data?.contents[findCurrentTrackIndex]?.id
                    )
                )
                dispatch(setTrackCount(findCurrentTrackIndex))
                dispatch(setPlaying(true))
                dispatch(setShowPlayer(true))
            }
        },
        [
            playing,
            currentTrackId,
            dispatch,
            currentPlayingCatalog?.id,
            sort,
            season,
            content?.catalogId,
            session?.token,
        ]
    )
    const playPlaylist = useCallback(
        async (id) => {
            if (playing && id === currentTrackId) {
                dispatch(setPlaying(!playing))
            } else {
                radioListenerCountHandler(-1, currentPlayingCatalog?.id)
                const response = await initializeOthersPlaylistAPI(
                    playlistId,
                    session?.token
                )

                dispatch(setPlaylist(response?.playlistContent))
                const findCurrentTrackIndex =
                    response?.playlistContent?.findIndex(
                        (value) => id === value.id
                    )
                dispatch(setPlaylist(response?.playlistContent))
                dispatch(
                    setCurrentPlayingCatalog(
                        response?.playlistContent[findCurrentTrackIndex]
                            ?.catalog
                    )
                )
                dispatch(
                    setCurrentTrackId(
                        response?.playlistContent[findCurrentTrackIndex]?.id
                    )
                )
                dispatch(setTrackCount(findCurrentTrackIndex))
                dispatch(setPlaying(true))
                dispatch(setShowPlayer(true))
            }
        },
        [
            playing,
            currentTrackId,
            dispatch,
            currentPlayingCatalog?.id,
            playlistId,
            session?.token,
        ]
    )

    return (
        <Container>
            <Link href={`/content/${content?.id}`} prefetch={false}>
                <span>
                    <BadgeContainer
                        isPremium={content?.isPremium}
                        hasPurchased={content?.hasPurchased}
                    >
                        {content?.badge === 'vip' && (
                            <img src="/img/vip.svg" alt="vip badge" />
                        )}
                        {content?.badge === 'earlyAccess' && (
                            <img
                                src="/img/early_access_new.svg"
                                alt="early access badge"
                            />
                        )}
                        {content?.badge === 'premium' && (
                            <img src="/img/premium.svg" alt="premium badge" />
                        )}

                        {!content?.hasPurchased && (
                            <>
                                <img
                                    alt="coin icon"
                                    className="coin"
                                    src="/coin.svg"
                                    loading="lazy"
                                />
                                <p>{content?.price} Coin</p>
                            </>
                        )}

                        {content?.hasPurchased && (
                            <Purchased>
                                <img
                                    src="/img/check_green.svg"
                                    alt="check icon"
                                />

                                <span>Sudah Dibeli</span>
                            </Purchased>
                        )}
                    </BadgeContainer>

                    <MetaInfoContainer
                        isPremium={content?.isPremium}
                        hasPurchased={content?.hasPurchased}
                    >
                        <PublishedAtText>
                            <time>
                                {createDateString(content?.publishedAt)}
                            </time>
                        </PublishedAtText>

                        {(!isNoicebook || contentType === 'playlist') && (
                            <ListensAndCommentsContainer>
                                {content?.meta?.aggregations?.comments > 0 && (
                                    <CommentsContainer>
                                        <CommentsIcon
                                            loading="lazy"
                                            src={'/img/comments-icon.svg'}
                                            alt="Comments"
                                        />
                                        <CommentsText>
                                            {NumberConversion(
                                                content?.meta?.aggregations
                                                    ?.comments
                                            )}
                                        </CommentsText>
                                    </CommentsContainer>
                                )}
                            </ListensAndCommentsContainer>
                        )}
                    </MetaInfoContainer>
                    <EpisodeInfoDescriptionContainer
                        href={`/content/${content?.id}`}
                    >
                        <EpisodeInfoContainer>
                            <EpisodeImageContainer isNoicebook={isNoicebook}>
                                {content?.isPremium &&
                                    !content?.hasPurchased && (
                                        <PremiumBlocker
                                            isNoicebook={isNoicebook}
                                        >
                                            <img
                                                src="/img/lock_fill.svg"
                                                width={20}
                                                height={20}
                                                alt="Content Locked"
                                                loading="lazy"
                                            />
                                        </PremiumBlocker>
                                    )}

                                <EpisodeImage isNoicebook={isNoicebook}>
                                    <img
                                        src={
                                            compressedImage(
                                                content?.image,
                                                content?.imageMeta
                                            ) ||
                                            (contentType === 'playlist' &&
                                                compressedImage(
                                                    content?.catalog?.image,
                                                    content?.catalog?.imageMeta
                                                )) ||
                                            compressedImage(
                                                catalogData?.image,
                                                catalogData?.imageMeta
                                            )
                                        }
                                        width={48}
                                        height={isNoicebook ? 64 : 48}
                                        alt={content?.title}
                                        loading="lazy"
                                        style={{ borderRadius: '8px' }}
                                    />
                                </EpisodeImage>
                            </EpisodeImageContainer>
                            <TitleAndCatalogContainer>
                                <TitleText>
                                    {content?.videoUrl && (
                                        <img
                                            style={{
                                                width: '18px',
                                                height: '18px',
                                                marginRight: '6px',
                                                marginTop: '-2px',
                                            }}
                                            alt="video icon"
                                            src="/img/video_icon.svg"
                                        />
                                    )}
                                    {content?.title}
                                </TitleText>
                                <CatalogName>{catalogName}</CatalogName>
                            </TitleAndCatalogContainer>
                        </EpisodeInfoContainer>
                        <EpisodeDescription>
                            {content?.description}
                        </EpisodeDescription>
                    </EpisodeInfoDescriptionContainer>
                </span>
            </Link>
            <ControlsContainer
                onClick={(e) => {
                    e.stopPropagation()

                    if (content?.isPremium && !content?.hasPurchased) {
                        if (
                            content?.isSubscriptionAvailable &&
                            !content?.earlyAccessFinishDate
                        ) {
                            dispatch(
                                setShowSubscriptionPrompt({
                                    state: true,
                                    selectedEpisodePrice: content?.price,
                                    currentSubscriptionContentId: content?.id,
                                    currentSubscriptionContentDetails: content,
                                    onlyEpisode: false,
                                })
                            )
                        } else {
                            dispatch(
                                setShowSubscriptionPrompt({
                                    state: true,
                                    selectedEpisodePrice: content?.price,
                                    currentSubscriptionContentId: content?.id,
                                    currentSubscriptionContentDetails: content,
                                    onlyEpisode: true,
                                })
                            )
                        }

                        dispatch(setCurrentContentId(content?.id))
                        return
                    }
                }}
            >
                <PlayButtonAndDurationContainer>
                    <IconButton
                        className="ripple ripple-button"
                        style={{
                            marginRight: '8px',
                        }}
                        onClick={async (e) => {
                            e.stopPropagation()

                            IMAinitAdPlayingToast(
                                imaConfig,
                                initializeIma,
                                ads,
                                initializeAds,
                                dispatch
                            )

                            if (ads?.showingAd) return

                            if (content?.isPremium && !content?.hasPurchased) {
                                // if (
                                //     content?.isSubscriptionAvailable &&
                                //     !content?.earlyAccessFinishDate
                                // ) {
                                //     dispatch(
                                //         setShowSubscriptionPrompt({
                                //             state: true,
                                //             selectedEpisodePrice:
                                //                 content?.price,
                                //             currentSubscriptionContentId:
                                //                 content?.id,
                                //             currentSubscriptionContentDetails:
                                //                 content,
                                //             onlyEpisode: false,
                                //         })
                                //     )
                                // } else {
                                //     dispatch(
                                //         setShowSubscriptionPrompt({
                                //             state: true,
                                //             selectedEpisodePrice:
                                //                 content?.price,
                                //             currentSubscriptionContentId:
                                //                 content?.id,
                                //             currentSubscriptionContentDetails:
                                //                 content,
                                //             onlyEpisode: true,
                                //         })
                                //     )
                                // }

                                // dispatch(setCurrentContentId(content?.id))

                                router.push(`/content/${content.id}`)
                                return
                            }

                            if (joinedLiveroom) {
                                await leave(pubnub)
                            }

                            contentType === 'playlist'
                                ? playPlaylist(content?.id)
                                : playEpisodes(content?.id)
                        }}
                    >
                        <PlayButton
                            loading="lazy"
                            isPremium={content?.isPremium}
                            hasPurchased={content?.hasPurchased}
                            src={
                                playing && currentTrackId === content?.id
                                    ? '/img/secondary-pause-button.svg'
                                    : '/img/secondary-play-button.svg'
                            }
                            alt="Play Content"
                        />
                    </IconButton>

                    <Duration>
                        {content?.duration < 60
                            ? content?.duration + 's'
                            : SecondsToTime(content?.duration)}
                    </Duration>
                </PlayButtonAndDurationContainer>
                <RightSideControlsContainer isNoicebook={isNoicebook}>
                    <CheckmarkImage
                        loading="lazy"
                        isPremium={content?.isPremium}
                        hasPurchased={content?.hasPurchased}
                        isNoicebook={isNoicebook}
                        alt="Check"
                        onClick={(e) => {
                            e.stopPropagation()

                            if (content?.isPremium && !content?.hasPurchased) {
                                if (
                                    content?.isSubscriptionAvailable &&
                                    !content?.earlyAccessFinishDate
                                ) {
                                    dispatch(
                                        setShowSubscriptionPrompt({
                                            state: true,
                                            selectedEpisodePrice:
                                                content?.price,
                                            currentSubscriptionContentId:
                                                content?.id,
                                            currentSubscriptionContentDetails:
                                                content,
                                            onlyEpisode: false,
                                        })
                                    )
                                } else {
                                    dispatch(
                                        setShowSubscriptionPrompt({
                                            state: true,
                                            selectedEpisodePrice:
                                                content?.price,
                                            currentSubscriptionContentId:
                                                content?.id,
                                            currentSubscriptionContentDetails:
                                                content,
                                            onlyEpisode: true,
                                        })
                                    )
                                }

                                dispatch(setCurrentContentId(content?.id))
                                return
                            }

                            dispatch(setShowDownloadPrompt(true))
                            dispatch(setCurrentContentId(content?.id))
                        }}
                        src={'/img/checkmark.svg'}
                    />
                    <AddToQueueImage
                        loading="lazy"
                        isPremium={content?.isPremium}
                        hasPurchased={content?.hasPurchased}
                        isNoicebook={isNoicebook}
                        contentType="playlist"
                        alt="Add to Queue"
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch(setShowDownloadPrompt(true))
                            dispatch(setCurrentContentId(content?.id))
                        }}
                        src={'/img/add-to-queue.svg'}
                    />
                    <DownloadImage
                        loading="lazy"
                        isPremium={content?.isPremium}
                        hasPurchased={content?.hasPurchased}
                        alt="Download"
                        onClick={(e) => {
                            e.stopPropagation()

                            if (content?.isPremium && !content?.hasPurchased) {
                                dispatch(
                                    setShowDownloadPrompt({
                                        state: true,
                                        text: 'Buka App untuk Akses Konten VIP',
                                    })
                                ) // show download popup - with different text
                                dispatch(setCurrentContentId(content?.id))
                                return
                            }

                            dispatch(setShowDownloadPrompt(true))
                            dispatch(setCurrentContentId(content?.id))
                        }}
                        src={'/img/download.svg'}
                    />
                </RightSideControlsContainer>
            </ControlsContainer>
        </Container>
    )
}

export const createDateString = (date) => {
    Date.prototype.toShortFormat = function () {
        let monthNames = [
            'Januari',
            'Februari',
            'Maret',
            'April',
            'Mei',
            'Juni',
            'Juli',
            'Agustus',
            'September',
            'Oktober',
            'November',
            'Desember',
        ]

        let day = this.getDate()

        let monthIndex = this.getMonth()
        let monthName = monthNames[monthIndex]

        let year = this.getFullYear()

        return `${day} ${monthName} ${year}`
    }

    let convertedDate = new Date(date)

    return convertedDate.toShortFormat()
}

import { createQueryString } from '@/helpers/createQueryString'
import { getFetcher } from '@/utils/auth'

export const getCatalogDetails = async (catalogId, query, token) => {
    if (!catalogId || catalogId === 'null') return {}

    try {
        const queryString = query && createQueryString(query)

        const catalogAPIURL = query
            ? `catalog-api/catalog/${catalogId}?includeEntities=["userAction","user"]${queryString}`
            : `catalog-api/catalog/${catalogId}?includeEntities=["userAction","user"]`

        const data = await getFetcher(catalogAPIURL, token)

        return data
    } catch (error) {
        console.log('catalog details error', error)
        throw error
    }
}

import { handleAdPlayingToast } from './handleAdPlayingToast'
import { handleIMAInitialization } from './handleIMAInitialization'

/**
 * Runs **handleIMAInitialization** and **handleAdPlayingToast** functions
 * @param {*} imaConfig redux store
 * @param {*} initializeIma redux store action
 * @param {*} ads redux store
 * @param {*} initializeAds redux store action
 * @param {*} dispatch redux dispatch function
 */
export const IMAinitAdPlayingToast = (
    imaConfig,
    initializeIma,
    ads,
    initializeAds,
    dispatch,
    contentData,
    catalogData,
    playlist
) => {
    handleIMAInitialization(
        imaConfig,
        dispatch,
        initializeIma,
        contentData,
        catalogData,
        playlist
    )
    handleAdPlayingToast(ads, dispatch, initializeAds)
}

/**
 * Checks if an ad is playing and sets adPlaying toast based on that
 * @param {*} ads redux store
 * @param {*} dispatch redux dispatch function
 * @param {*} initializeAds redux store action
 */
export const handleAdPlayingToast = (ads, dispatch, initializeAds) => {
    if (ads?.showingAd) {
        dispatch(
            initializeAds({
                adPlayingToast: true,
            })
        )
    }
}

import { ColumnFlex, FlexCentered, RowFlex } from '@/themes/genericStyles'
import styled, { css } from 'styled-components'

export const Container = styled.div`
    ${ColumnFlex}
    justify-content: space-around;
    gap: 12px;
    padding: 16px;
    background: #202020;
    border-radius: 8px;
    width: 100%;
    @media (min-width: 992px) {
        height: auto;
        min-height: 200px;
    }
`

export const MetaInfoContainer = styled.div`
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    display: flex;
`

export const BadgeContainer = styled.div`
    width: 100%;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
    display: ${(props) => (props.isPremium ? 'flex' : 'none')};

    p {
        color: #fbde37;
        margin: 0px;

        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        letter-spacing: 0.25px;
    }

    .vip {
        height: 16px;
    }

    .coin {
        height: 16px;
        margin-right: 5px;
        margin-left: 10px;
    }

    .noice_premium {
        margin-left: 10px;
    }
`

export const PublishedAtText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
`

export const ListensAndCommentsContainer = styled.div`
    ${RowFlex}
    // width: 21%;
    justify-content: space-around;
    // @media (min-width: 992px) {
    //     width: 15%;
    // }
`

export const ListenContainer = styled.div`
    ${RowFlex}
    ${FlexCentered}
    margin-right: 5px;
`
export const ListenIcon = styled.img`
    width: 11px;
    height: 11px;
    margin-right: 5px;
`

export const ListenText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 70%;
    color: rgba(250, 250, 250, 0.7);
`

export const CommentsContainer = styled.div`
    ${RowFlex}
    ${FlexCentered}
    margin-right: 5px;
`
export const CommentsIcon = styled.img`
    width: 11px;
    height: 11px;
    margin-right: 5px;
`

export const CommentsText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 70%;
    color: rgba(250, 250, 250, 0.7);
`

export const EpisodeInfoContainer = styled.div`
    ${RowFlex}
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    @media (min-width: 992px) {
        justify-content: flex-start;
    }
`

export const EpisodeInfoDescriptionContainer = styled.a`
    ${ColumnFlex}
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 992px) {
        justify-content: flex-start;
    }
`

export const EpisodeImageContainer = styled.div`
    position: relative;
    width: 48px;
    height: ${(props) => (props.isNoicebook ? '64px' : '48px')};
    margin-right: 2vh;
    border-radius: 8px;
`

export const PremiumBlocker = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: ${(props) => (props.isNoicebook ? '64px' : '48px')};
    background: rgba(0, 0, 0, 0.7);
    display: grid;
    place-content: center;
    border-radius: 8px;
`

export const EpisodeImage = styled.div`
    width: 48px;
    height: 48px;
    object-fit: cover;
    // height: auto;
    border-radius: 4px;
    ${({ isNoicebook }) =>
        isNoicebook &&
        css`
            width: 48px;
            height: 64px;
        `}
`

export const TitleAndCatalogContainer = styled.div`
    ${ColumnFlex}
    gap: 6px;
    width: 81.08%;
    height: 100%;
`

export const TitleText = styled.h3`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 17.5px;
    color: #fafafa;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
`

export const CatalogName = styled.h4`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: #c4c4c4;
    margin: 0;
`

export const EpisodeDescription = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #b9b9b9;
    margin: 0;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
`

export const ControlsContainer = styled.div`
    ${RowFlex}
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

export const PlayButtonAndDurationContainer = styled.div`
    ${RowFlex}
    align-items: center;
    justify-content: flex-start;
    width: 56.75%;
    height: 100%;
    position: relative;
`

export const PlayButton = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;
`

export const Duration = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.7);
`

export const RightSideControlsContainer = styled.div`
    ${RowFlex}
    align-items: center;
    justify-content: flex-end;
    width: 40.54%;
    ${({ isNoicebook }) =>
        isNoicebook &&
        css`
            justify-content: end;
        `}
    @media (min-width: 992px) {
        width: 30%;
    }
`

export const CheckmarkImage = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-right: 2vh;
    opacity: ${(props) => (props?.isPremium && !props?.hasPurchased ? 0.5 : 1)};
    ${({ isNoicebook }) =>
        isNoicebook &&
        css`
            margin-right: 12px;
        `};
`

export const AddToQueueImage = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-right: 2vh;
    opacity: ${(props) => (props?.isPremium && !props?.hasPurchased ? 0.5 : 1)};
    ${({ isNoicebook }) =>
        isNoicebook &&
        css`
            margin-right: unset;
        `};
    ${({ contentType }) =>
        contentType &&
        css`
            margin-right: 2vh;
        `};
`

export const DownloadImage = styled.img`
    opacity: ${(props) => (props?.isPremium && !props?.hasPurchased ? 0.5 : 1)};
    width: 32px;
    height: 32px;
    cursor: pointer;
`

export const Purchased = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    color: #82d076;
    margin-left: 10px;

    img {
        width: 16px;
        height: 16px;
    }

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        letter-spacing: 0.25px;
    }
`

export const IconButton = styled.button`
    border: none;
    background: none;
    width: max-content;
    padding: 0px;
`
